<template>
    <tbody v-if='isGroupReceipt && isRegisteredByMe'>
      <tr class='border-b border-gray-200 text-gray-900 font-medium hover:bg-gray-50'>
        <td class='py-2 pr-2 text-left'>{{receipt.registrationNumber}}</td>
        <td>{{participantsCount}} participants</td>
        <td></td>
        <td class='py-2 pl-2 text-right'>{{currencyUnit}} {{totalFee}}</td>
        <td class='py-2 pl-2 text-right'>
          <router-link
            :to='{ name: "ReceiptDownloadFileGroup", query: { "registration_no": receipt.registrationNumber} }'
            target='_blank'
            class='hover:underline text-blue-900 uppercase font-normal'>
            Group Receipt
          </router-link>
        </td>
      </tr>
      <tr v-for='(availableReceipt, index) in receipt.byRegistrationNumber'
        :key='`available-${availableReceipt.profile.name}-${availableReceipt.profile.licenseNumber}-${index}`'
        class='border-t border-gray-100 hover:bg-gray-50 text-gray-700'>
        <td></td>
        <td class='py-2 pl-2 text-left'>{{availableReceipt.profile.licenseNumber}}</td>
        <td class='py-2 pl-2 text-left'>{{availableReceipt.profile.name}}</td> 
        <td class='py-2 pl-2 text-right'>{{availableReceipt.registrationData.registrationFee}}</td>
        <td class='py-2 pl-2 text-right'>
          <router-link v-if='availableReceipt.registrationData.payment'
            :to='{ name: "ReceiptDownloadFile", query: {
              "licenseNumber": availableReceipt.profile.licenseNumber,
              "name": availableReceipt.profile.name } }'
            target='_blank'
            class='hover:underline text-blue-900 uppercase'>
            Single Receipt
          </router-link>
          <span v-else class='text-red-600 uppercase'>has not paid yet</span>
        </td>
      </tr>
    </tbody>
    <tbody v-else>
      <tr v-for='(availableReceipt, index) in receipt.byRegistrationNumber'
        :key='`available-${availableReceipt.profile.name}-${availableReceipt.profile.licenseNumber}-${index}`'
        class='border-t border-gray-200 hover:bg-gray-50 text-gray-700 font-medium'>
        <td class='py-2 pr-2 text-left'>{{receipt.registrationNumber}}</td>
        <td class='py-2 pl-2 text-left'>{{availableReceipt.profile.licenseNumber}}</td>
        <td class='py-2 pl-2 text-left'>{{availableReceipt.profile.name}}</td> 
        <td class='py-2 pl-2 text-right'>{{availableReceipt.registrationData.registrationFee}}</td>
        <td class='py-2 pl-2 text-right'>
          <router-link v-if='availableReceipt.registrationData.payment'
            :to='{ name: "ReceiptDownloadFile", query: {
              "licenseNumber": availableReceipt.profile.licenseNumber,
              "name": availableReceipt.profile.name } }'
            target='_blank'
            class='hover:underline text-blue-900 uppercase'>
            Single Receipt
          </router-link>
          <span v-else class='text-red-600 uppercase'>has not paid yet</span>
        </td>
      </tr>
    </tbody>
</template>

<script>

export default {
  name: 'ReceiptDownloadPerRegistration',
  props: {
    receipt: {
      type: Object,
      required: true
    }
  },
  computed: {
    isRegisteredByMe () {
      return (this.receipt.byRegistrationNumber) ? this.receipt.byRegistrationNumber.every(ele => ele.isRegisteredByMe) : false
    },
    isGroupReceipt () {
      return this.receipt.registrationNumber && this.receipt.registrationNumber.charAt(0).toLowerCase() === 'g'
    },
    totalFee () {
      return (this.receipt.byRegistrationNumber) 
        ? this.receipt.byRegistrationNumber.reduce((acc, curr) => {
            return acc + parseInt(curr.registrationData.registrationFee.split(' ')[1].replaceAll(',', ''))
          }, 0)
        : 0
    },
    currencyUnit () {
      return (this.receipt.byRegistrationNumber) ? this.receipt.byRegistrationNumber[0].registrationData.registrationFee.split(' ')[0] : ''
    },
    participantsCount () {
      return (this.receipt.byRegistrationNumber) ? this.receipt.byRegistrationNumber.length : 0
    },
  },
}
</script>
