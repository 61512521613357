<template>
<div class='flex flex-col lg:flex-row justify-between items-stretch gap-x-20'>
  <div
    class='hidden background-style text-white py-4 px-4 lg:py-16 lg:px-16 flex-shrink-0 w-full lg:w-96'
    :style='`${backgroundStyle}`'>
    <h2 class='text-2xl uppercase'>Receipt Lookup</h2>
    <h3 class='text-normal mt-2 leading-5'>Please enter in the license number and name to lookup paid reciepts.</h3>
  </div>
  <div class='flex-grow relative py-8 lg:py-16 px-4 lg:px-0'>
    <div class='bg-gray-50 p-8 pb-12 rounded-md border'>
      <h2 class='text-xl font-semibold'>Lookup Receipt</h2>
      <div class='my-4'>
        <label class='uppercase text-gray-600 mb-2 block'>License Number</label>
        <div><input v-model='licenseField' type='text' class='border rounded py-2 px-2 w-96'/></div>
      </div>
      <div class='my-4'>
        <label class='uppercase text-gray-600 mb-2 block'>Name (국문)</label>
        <div><input v-model='nameField' type='text' class='border rounded py-2 px-2 w-96'/></div>
      </div>
      <div><button class='w-96 py-4 bg-gray-900 text-white uppercase rounded hover:shadow-md' @click='lookupByLicenseAndName'>Lookup</button></div>
    </div>
    <div class='mt-8 text-base'>
      <table class='w-full table-fixed' v-if='hasFoundReceipts'>
        <thead>
          <tr class='border-b'>
            <th class='py-2 pr-2 uppercase text-left whitespace-nowrap'>Registration #</th>
            <th class='py-2 px-2 uppercase text-left'>License #</th>
            <th class='py-2 px-2 uppercase text-left'>Name</th>
            <th class='py-2 px-2 uppercase text-right'>Fee</th>
            <th class='py-2 pl-2 uppercase text-right w-1/3'>download</th>
          </tr>
        </thead>
        <receipt-download-per-registration v-for='(receipt, index) in receiptLookups'
          :key='`receipt${index}-for-${receipt.licenseNumber}`'
          class='border-b'
          :receipt='receipt' />
      </table>
      <div v-else-if='lookupError'
        class='p-16 rounded-md bg-red-50 text-center border border-red-200 shadow'>
        The License number or name is not valid. 
        Please check and try again.
      </div>
      <div v-else-if='lookingUpNow'
        class='p-16 rounded-md bg-transparent text-center border border-gray-50'>
        <loader-icon class='spinner inline-block' /> Checking
      </div>
      <div v-else
        class='p-16 rounded-md bg-gray-50 text-center'>
        Please enter in the license number and name to lookup paid reciepts.
      </div>
    </div>
  </div>
</div>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { LoaderIcon } from 'vue-tabler-icons'
import groupBy from 'lodash/groupBy' 
import ReceiptDownloadPerRegistration from '@/components/ReceiptDownloadPerRegistration.vue'

export default {
  name: 'ReceiptDownload',
  components: {
    LoaderIcon,
    ReceiptDownloadPerRegistration,
  },
  data () {
    return {
      licenseField: '',
      nameField: '',
      receiptLookups: [],
      lookupStatus: 'empty'
    }
  },
  computed: {
    ...mapGetters('events', [
      'eventImageS3Path',
      'eventMainThemeColor',
    ]),
    backgroundStyle () {
      return `background-color: ${this.eventMainThemeColor};`
    },
    hasFoundReceipts () {
      return this.lookupStatus === 'success' && this.receiptLookups.length > 0
    },
    lookupError () {
      return this.lookupStatus === 'error'
    },
    lookingUpNow () {
      return this.lookupStatus === 'loading'
    }
  },
  methods: {
    ...mapActions('users', [
      'getRegisteredByLicenseAndName',
    ]),
    lookupByLicenseAndName () {
      this.lookupStatus = 'loading'
      this.receiptLookups = []
      
      this.getRegisteredByLicenseAndName({
        'licenseNumber': this.licenseField,
        'name':          this.nameField
      }).then(resp => {
        if (resp.registeredUsers.participantUnknown) {
          this.lookupStatus = 'error'
        } else {
          this.lookupStatus = 'success'
          this.resetFields()
          let tmp = groupBy(resp.registeredUsers, 'registrationNumber')
          for (const [key, value] of Object.entries(tmp)) {
            this.receiptLookups.push({
              'registrationNumber': key,
              'byRegistrationNumber': value
            })
          }
        }
      })
    },
    resetFields () {
      this.licenseField = ''
      this.nameField    = ''
    },
  },
}
</script>

<style lang='scss' scoped>
.spinner {
  animation: spin 1.5s linear infinite;
}
@keyframes spin {
  0%   { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>